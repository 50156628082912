import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { setProgressBar } from "../login";
import { HistoryButtons, containersProfileChange } from "../profile";

export default function Notifications() {
    var { id } = useParams();

    console.log(id);
    useEffect(function () {

        containersProfileChange()
        setProgressBar();
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="opacity-animation notifications">
            <HistoryButtons />
            <h3>Notificações</h3>
            <div className="expired">
                <p>Vencimento do seu plano Dark Translate</p>
                <Link to="" >Realizar pagamento</Link>
            </div>
            <div>
                <p>Vencimento do seu plano Dark Translate</p>
            </div>
        </div>
    )
}