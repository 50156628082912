import { Link, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import "../css/profile.css";
import { useEffect } from "react";
import { setProgressBar } from "./login";

var d = document;

/* PAGES */
function PreCheckout() {
    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="opacity-animation">
            <HistoryButtons />
            <h1>Pre Checkout</h1>
            <Link to="./info">Info</Link>
        </div>
    )
}

function DirectInfo() {

    return (
        <div className="opacity-animation">
            <HistoryButtons />
            <Routes>
                <Route path="*" Component={InfoCheckout} />
                <Route path="checkout" Component={FinalCheckout} />
            </Routes>
        </div>
    )
}
function InfoCheckout() {
    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="opacity-animation">
            <h1>InfoChecout</h1>
            <Link to="./checkout">Final</Link>
            <Routes>
                <Route path="checkout" Component={FinalCheckout} />
            </Routes>
        </div>
    )
}

function FinalCheckout() {
    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="opacity-animation">
            <h1>Final checout</h1>
        </div>
    )
}

function HistoryButtons() {
    var navigate = useNavigate();
    return (
        <section id="history-nvht">
            <button onClick={() => navigate(-1)}><svg viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M10,-2.27373675e-13 C15.51,-2.27373675e-13 20,4.49 20,10 C20,15.52 15.51,20 10,20 L10,20 L9.72023508,19.9961558 C4.32942258,19.8478368 -2.26761352e-13,15.4264407 -2.26761352e-13,10 C-2.26761352e-13,4.49 4.48,-2.27373675e-13 10,-2.27373675e-13 Z M8.56,5.78 C8.36,5.78 8.17,5.85 8.02,6 C7.73,6.3 7.73,6.77 8.03,7.06 L8.03,7.06 L10.98,10 L8.03,12.94 C7.73,13.23 7.73,13.71 8.02,14 C8.32,14.3 8.79,14.3 9.08,14 L9.08,14 L12.57,10.53 C12.71,10.39 12.79,10.2 12.79,10 C12.79,9.8 12.71,9.61 12.57,9.47 L12.57,9.47 L9.08,6 C8.94,5.85 8.75,5.78 8.56,5.78 Z"></path></svg></button>
        </section>
    )
}

export default function Checkout() {
    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
    }, []);
    return (
        <main id="profile">
            <div className="view-animation">
                <Routes>
                    <Route path="*" Component={PreCheckout} />
                    <Route path="info/*" Component={DirectInfo} />
                </Routes>
            </div>
        </main>
    )
}



export { setProgressBar }