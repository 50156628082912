import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Home from './components/home';
import "./css/preset.css"
import "./css/animations.css"
import Header from './components/header';
import Footer from './components/footer';
import Credentials from './components/credentials';
import Checkout from './components/checkout';
import UnderDevelopment from './components/under-development';
import IdeasSession from './components/ideas';
import SessionRestrict from './components/restrict/restrict';

var env = process.env;

function Services() {
   return (
      <div>
         <h1>Im Services</h1>
         <h2>I Have {env.REACT_APP_AGE}</h2>
         <Link to="../" >Home</Link>
      </div>
   )
}

function App() {
   return (
      <BrowserRouter>
         <Header />
         <Routes>
            <Route path='/' Component={Home} />
            <Route path='/services' Component={Services} />
            <Route path='/credentials/*' Component={Credentials} />
            <Route path='/pre-checkout/*' Component={Checkout} />
            <Route path='/ideas/*' Component={IdeasSession} />
            <Route path='/restrict/*' Component={SessionRestrict} />
            <Route path="*" Component={UnderDevelopment} />
         </Routes>
         <Footer />
      </BrowserRouter>
   );
}

export default App;
