import { Link, Route, Routes } from "react-router-dom"
import { ShareConfirmPassword } from "../header"
import { useEffect } from "react";
import "../../css/restrict.css";
import axios from "axios";

function Dashboard() {

   return (
      <div className="restrict" >
         <div>
            Dashboard
            <Link to="clients">Clientes</Link>
            <Link to="payments">Pagamentos</Link>
            <Link to="projects">Projetos</Link>
            <Link to="ideas">Ideas</Link>
            <Link to="./">Voltar</Link>
         </div>
      </div>
   )
}

//CLIENTES
function Clients() {
   useEffect(() => {
   }, []);

   return (
      <div className="restrict">
         <div>
            Clients
            <Link to="../clients"  >Clientes</Link>
            <Link to="../payments">Pagamentos</Link>
            <Link to="../projects">Projetos</Link>
            <Link to="../">Voltar</Link>
         </div>
      </div>
   )
}

//PROJECTS 
var axiosCreate = axios.create({
   baseURL: "http://localhost:8080",
   timeout: 10000
});

function Home() {
   useEffect(() => {
   }, []);

   return (
      <div className="restrict">
         <div>
            <h1>Clientes</h1>
            <Link to="./add">Adicionar novo cliente</Link>
            <Link to="../">Voltar</Link>
         </div>
      </div>
   )
}

async function addNewProject(e) {
   var data = {
      release: Date.now(),
      url: "https://projeto.com.br/projects",
      icon: "dark-translate",
      "project-name": e.target.previousElementSibling.value,
      title: " Sou um titulo para esse projeto.",
      description: "Esse projeto é uma solução sofisticada e abrangente, inteiramente construída em JavaScript puro, o que garante sua compatibilidade e desempenho excepcionais em diversas plataformas e ambientes.",
   };

   await axiosCreate.post("/projects/add?comment=Bombas ICBM <script> \x00", data).then(e => {
      console.log("Sended: ");
      console.log(e.data);
      alert(JSON.stringify(e.data))
   }).catch(e => {
      console.log("Error: ");
      console.log(e);
   }).finally(e => {
      console.log("Ended: ");
      console.log(e);
   })
}

function Add() {

   useEffect(function () {
   }, [])

   return (
      <div className="restrict">
         <div>
            <h1>Adicionar novo cliente</h1>
            <input title="" placeholder="Project Name" />
            <button onClick={addNewProject}>Adicionar</button>
            <button onClick={addNewProject}>Adicionar 2</button>
            <Link to="../">Voltar</Link>
         </div>
      </div>
   )
}

function Projects() {
   return (
      <Routes>
         <Route path="*" Component={Home} />
         <Route path="add" Component={Add} />
      </Routes>
   )
}

function Payments() {
   return (
      <div className="restrict">
         <div>
            Payments
            <Link to="../clients">Clientes</Link>
            <Link to="../payments">Pagamentos</Link>
            <Link to="../">Voltar</Link>
         </div>
      </div>
   )
}

async function getIdeas() {
   await axiosCreate.get("/ideas/").then(e => {
      console.log(e.data);
   }).catch(e => {
   });
}

function Ideas() {
   useEffect(() => {
   }, [])
   return (
      <div className="restrict" onClick={getIdeas}>
         <div>
            Ideas
            <Link to="../clients">Clientes</Link>
            <Link to="../payments">Pagamentos</Link>
            <Link to="../">Voltar</Link>
         </div>
      </div>
   )
}

export default function SessionRestrict() {
   var { allowed, confirmed, notAllowed } = ShareConfirmPassword();

   useEffect(function () {
      //notAllowed();
      //confirmed();
   }, []);

   allowed = true

   return (
      <Routes>
         <Route path="*" Component={allowed ? Dashboard : null} />
         <Route path="clients" Component={allowed ? Clients : null} />
         <Route path="payments" Component={allowed ? Payments : null} />
         <Route path="ideas" Component={allowed ? Ideas : null} />
         <Route path="projects/*" Component={Projects} />
      </Routes>
   )
}
export { axiosCreate }