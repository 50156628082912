import { useEffect } from "react";
import { HistoryButtons, containersProfileChange, setProgressBar } from "../profile";
import { InputFormatalidate } from "../../modules/input-formatalidate/input-formatalidate";
import { ShareConfirmPassword } from "../header";
import { Route, Routes } from "react-router-dom";

function EditProfile() {
    useEffect(function () {
        containersProfileChange()
        InputFormatalidate({
            buttonSubmit: ".profile-button",
            container: ".profile-form",
            errorMessages: {}
        }, function (e) {
            console.log("Update profile");
            console.table(e);
        });

        setProgressBar();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="opacity-animation profile-form">
            <HistoryButtons />
            <h3>Editar Perfil</h3>
            <div>
                <h4>Name</h4>
                <input title="" autoComplete="name" type="text" placeholder="Chris Axxwell" name="full-name" required />
                <span></span>
            </div>
            <div>
                <h4>Username</h4>
                <input title="" autoComplete="username" type="text" placeholder="@chrisaxxwell" name="username" required />
                <span></span>
            </div>
            <div>
                <h4>Telefone</h4>
                <input title="" autoComplete="tel" type="text" placeholder="+55 (74) 9 9911 2214" name="full-tel" required />
                <span></span>
            </div>
            <div>
                <h4>Email</h4>
                <input title="" autoComplete="email" type="email" placeholder="chrisaxxwell@gmail.com" name="email" required />
                <span></span>
                <h5>Apos atualizar enviaremos um email de confirmação, acesse seu email para atualizar.</h5>
            </div>
            <div>
                <h4>Aniversário</h4>
                <input title="" autoComplete="text" type="text" placeholder="DD/MM/YYYY" name="birthdate" required />
                <span></span>
            </div>
            <h4>Endereço</h4>
            <div>
                <h4>Country</h4>
                <input title="" autoComplete="text" type="text" placeholder="Brazil" name="country" required />
                <span></span>
            </div>
            <div>
                <h4>Postal code</h4>
                <input title="" autoComplete="postal-code" type="text" placeholder="09931 - 210" name="postal-code" required />
                <span></span>
            </div>
            <div>
                <h4>State</h4>
                <input title="" autoComplete="state" type="text" placeholder="--" name="state" required />
                <span></span>
            </div>
            <div>
                <h4>City</h4>
                <input title="" autoComplete="city" type="text" placeholder="São Bernardo do Campo" name="city" required />
                <span></span>
            </div>
            <div>
                <h4>Address</h4>
                <input title="" autoComplete="address-level1" type="text" placeholder="Assunção, estrada dos alvarengas 9551" name="address" required />
                <span></span>
            </div>
            <button className="profile-button"><span>Salvar alterações</span></button>
        </div>
    )
}

export default function SessionEditProfile() {
    var { allowed, confirmed, notAllowed } = ShareConfirmPassword();

    useEffect(() => {
        notAllowed();
        confirmed();
    }, []);

    return (
        <Routes>
            <Route path="*" Component={allowed ? EditProfile : null} />
        </Routes>
    )
}