import { useEffect } from "react";
import { setProgressBar } from "../login";
import { HistoryButtons, containersProfileChange } from "../profile";
import { Link, Route, Routes, useParams } from "react-router-dom";
import { InputFormatalidate } from "../../modules/input-formatalidate/input-formatalidate";
import { ShareConfirmPassword } from "../header";

function CredentialsProfile() {
    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
        containersProfileChange()
    }, []);
    return (
        <div className="opacity-animation credentials">
            <HistoryButtons />
            <h3>Credenciais</h3>
            <div className="expired">
                <h3 className="main-theme">DarkTranslate</h3>
                <h4>Data de aquisição: 13/02/2024 22:23</h4>
                <h4>Vencimento: 13/03/2024</h4>
                <p>Domínio: https://sitedaaquisicao.com</p>
                <Link to="../acquared-credential/4628944201294239033357291205" >Dados da aquisição / Editar</Link>
                <div>
                    credencial: <code>axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</code>
                </div>
            </div>
            <div>
                <h3 className="main-theme">DarkTranslate</h3>
                <h4>Data de aquisição: 13/02/2024 22:23</h4>
                <p>Domínio: https://sitedaaquisicao.com</p>
                <Link to="../acquared-credential/856567345234234132436545234" >Dados da aquisição / Editar</Link>
                <div>
                    credencial: <code>axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</code>
                </div>
            </div>
            <div>
                <h3 className="main-theme">DarkTranslate</h3>
                <h4>Data de aquisição: 13/02/2024 22:23</h4>
                <p>Domínio: https://sitedaaquisicao.com</p>
                <Link to="../acquared-credential/126123523412312352313231241" >Dados da aquisição / Editar</Link>
                <div>
                    credencial: <code>axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</code>
                </div>
            </div>
            <div>
                <h3 className="main-theme">DarkTranslate</h3>
                <h4>Data de aquisição: 13/02/2024 22:23</h4>
                <p>Domínio: https://sitedaaquisicao.com</p>
                <Link to="../acquared-credential/4628944201294239033357291205" >Dados da aquisição / Editar</Link>
                <div>
                    credencial: <code>axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</code>
                </div>
            </div>
            <div>
                <h3 className="main-theme">DarkTranslate</h3>
                <h4>Data de aquisição: 13/02/2024 22:23</h4>
                <p>Domínio: https://sitedaaquisicao.com</p>
                <Link to="../acquared-credential/4628944201294239033357291205" >Dados da aquisição / Editar</Link>
                <div>
                    credencial: <code>axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</code>
                </div>
            </div>
            <div>
                <h3 className="main-theme">DarkTranslate</h3>
                <h4>Data de aquisição: 13/02/2024 22:23</h4>
                <p>Domínio: https://sitedaaquisicao.com</p>
                <Link to="../acquared-credential/4628944201294239033357291205" >Dados da aquisição / Editar</Link>
                <div>
                    credencial: <code>axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</code>
                </div>
            </div>
            <div>
                <h3 className="main-theme">DarkTranslate</h3>
                <h4>Data de aquisição: 13/02/2024 22:23</h4>
                <p>Domínio: https://sitedaaquisicao.com</p>
                <Link to="../acquared-credential/4628944201294239033357291205" >Dados da aquisição / Editar</Link>
                <div>
                    credencial: <code>axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</code>
                </div>
            </div>
            <div>
                <h3 className="main-theme">DarkTranslate</h3>
                <h4>Data de aquisição: 13/02/2024 22:23</h4>
                <p>Domínio: https://sitedaaquisicao.com</p>
                <Link to="../acquared-credential/4628944201294239033357291205" >Dados da aquisição / Editar</Link>
                <div>
                    credencial: <code>axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</code>
                </div>
            </div>
        </div>
    )
}

function AcquaredCredential() {
    var { id } = useParams();

    console.log(id);
    useEffect(function () {
        InputFormatalidate({
            container: ".change-credentials-atrib",
            buttonSubmit: "#change-credentials-atrib-btn",
            errorMessages: {}
        }, function (e) {
            console.log("Change credentials atributes");
            console.table(e);
        })
        setProgressBar();
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="opacity-animation change-credentials-atrib">
            <HistoryButtons />
            <h3>Dados da credencial</h3>
            <div>
                <h4>Nome:</h4>
                <p>Pablo Marçal</p>
            </div>
            <div>
                <h4>Credencial:</h4>
                <h5>Utilize essa credencial para ter o acesso.</h5>
                <p className="main-theme">axxwell_44#eE32%$SFw34%4@44SHqweHT65#$Iut@#</p>
            </div>
            <div>
                <h4>Data de aquisição:</h4>
                <p>13/02/2024</p>
            </div>
            <div>
                <h4>Identificação:</h4>
                <p>4628944201294239033357291205</p>
            </div>
            <div>
                <h4>Valor:</h4>
                <p>R$ 10,00 mensais</p>
            </div>
            <div>
                <h4>Tipo de aquisição:</h4>
                <p>Mensal</p>
            </div>
            <div>
                <h4>Vencimento:</h4>
                <p>14/12/2024 (30 dias)</p>
            </div>
            <div>
                <h4>Dominio registrado:</h4>
                <input title="" name="url" type="url" placeholder="https://sobremesasdasa.com.br" required title="" />
                <span></span>
            </div>
            <div>
                <h4>Contato para notificação:</h4>
                <h5>Email ou telefone para envio de notificacao de vencimento ou outros.</h5>
                <input title="" name="all-contacts" type="text" placeholder="+55 (11 99900-0000 / chrisaxx@gmail.com" required title="" />
                <span></span>
            </div>
            <button id="change-credentials-atrib-btn" className="profile-button"><span>Salvar alterações</span></button>
        </div>
    )
}

export default function SessionCredentialsProfile() {
    var { allowed, confirmed, notAllowed } = ShareConfirmPassword();

    useEffect(() => {
        notAllowed();
        confirmed();
    }, [])

    return (
        <Routes>
            <Route path="*" Component={allowed ? CredentialsProfile : null} />
            <Route path="acquared-credential/:id" Component={allowed ? AcquaredCredential : null} />

        </Routes>
    )
} 