/**
 *  * Info: Defina data-required="true" no input para inputs obrigatorios
     * <div>
        <p>Nome (Opcional)</p>
        <input data-required="true" autoCapitalize="true" placeholder="eg: John Smith" type="text" required name="name" />
        <span></span> para definir as mensagens de erro
    </div> 

    o input de cartao deve ter o figure antes para setar a bandeira
    <div>
        <p>Credit card (Opcional)</p>
        <figure></figure> Para definir as bandeiras
        <input data-required="true" placeholder="eg: 0000 0000 0000 0000" type="text" required name="cc-number" />
        <span></span> para definir as mensagens de erro
    </div>

    Usabilidade
      InputFormatalidate(
        //Configuracoes, como container dos inputs, botao,e mensagens customizadas
        {
            container: ".submitIdea",
            buttonSubmit: ".submit-button",
            errorMessages: {
                name: "Nome invalido!"
            }
        }, 
        //Funcao que obtem os dados para depois de validados, sanitizados e etc...
        //Faca a validacao no servidor tambem para garantir segurança
        function (e) {
            console.log(e);
        })
 */

const CCNumberElements = require("./cc-number");
const DDIFormats = require("./ddi-format");

var d = document;
var w = window;
var realFormat = null;
var dataForm = {};
var gb = "getBoundingClientRect";
var def = {};
var errorMessage = {
   "name": "Invalid name!",
   "full-name": "Invalid full name!",
   "first-name": "Invalid First name!",
   "given-name": "Invalid Given name!",
   "last-name": "Invalid Last name!",
   "family-name": "Invalid Family name!",
   "all-contacts": "Invalid contact!, define email or phone number",

   //Login/sign
   "username": "Username invalid!",
   "user-login": "Invalid login user or password!",
   "current-password": "Invalid current password!",
   "access-password": "Invalid access password!",
   "login-password": "Invalid password or login user!",
   "password": "Invalid password, requirements:<br>1: Minimum 6 characters;<br>2: An uppercase letter;<br> 3: A lowercase letter;<br> 4: A number;<br> 5: May contain spaces.",
   "re-password": "Essa senha deve ser igual a de cima!",

   //Texts
   "subject": "invalid subject, min 5, max 300 characters!",
   "message": "Invalid message, min 10, max 3000 characters!",
   "birthdate": "Invalid Birth date, please define an correct date!",
   "url": "Invalid domain url!, use https://, like https://chrisaxxwell.com",

   "full-tel": "Invalid phone number! +X XXXXXXXXX",
   " tel": "Invalid phone number!",
   "email": "Invalid email, dont allowed @@, .@, .com.some....!",
   "cc-number": "Invalid card number",
   "cc-expire-date": "Invalid expire date!",
   "cc-cvc": "Invalid CVC card!",
   "invalid": "Invalid value!",

   //Address
   "country": "Invalid country!",
   "state": "Invalid state!",
   "city": "Invalid city!",
   "address": "Invalid address!",
   "postal-code": "Invalid address!",
};

//REGEXS
var regexPrevent = {
   //data user
   "name": [ "a-zA-Zá-úÁ-Ú\\s", [ 0, 200 ] ],
   "full-name": [ "a-zA-Zá-úÁ-Ú\\s", [ 0, 200 ] ],
   "first-name": [ "a-zA-Zá-úÁ-Ú", [ 0, 100 ] ],
   "given-name": [ "a-zA-Zá-úÁ-Ú", [ 0, 100 ] ],
   "last-name": [ "a-zA-Zá-úÁ-Ú", [ 0, 100 ] ],
   "family-name": [ "a-zA-Zá-úÁ-Ú", [ 0, 100 ] ],

   //login/sign
   "username": [ "a-zA-Z0-9._", [ 0, 30 ] ],
   "user-login": [ "a-zA-Z0-9@!#$%&*_=.+}/?'{~|~-", [ 0, 200 ] ], //Login que permite username, email e telefone
   "all-contacts": [ "a-zA-Z0-9@!#$%&*_=.+}/?'{~|~-", [ 0, 200 ] ], //Login que permite username, email e telefone
   "current-password": [ "a-zA-Z0-9!@#$¨á-úÁ-Ú%^\\s&*()_+\\-=\\[\\]{};':\"\\|,.<>\\/?", [ 0, 255 ] ],
   "access-password": [ "a-zA-Z0-9!@#$¨á-úÁ-Ú%^\\s&*()_+\\-=\\[\\]{};':\"\\|,.<>\\/?", [ 0, 20 ] ],
   "login-password": [ "a-zA-Z0-9!@#$¨á-úÁ-Ú%^\\s&*()_+\\-=\\[\\]{};':\"\\|,.<>\\/?", [ 0, 255 ] ],
   "password": [ "a-zA-Z0-9!@#$¨á-úÁ-Ú%^\\s&*()_+\\-=\\[\\]{};':\"\\|,.<>\\/?", [ 0, 255 ] ],
   "re-password": [ "a-zA-Z0-9!@#$¨á-úÁ-Ú%^\\s&*()_+\\-=\\[\\]{};':\"\\|,.<>\\/?", [ 0, 255 ] ],

   //data
   "tel": [ "0-9()+\\-\\s", [ 0, 20 ] ],
   "full-tel": [ "0-9()+\\-\\s", [ 0, 20 ] ],
   "email": [ "a-zA-Z0-9!#@/?$%&*_=.+}'{~|~-", [ 0, 200 ] ],
   "birthdate": [ "0-9/", [ 0, 10 ] ],
   "url": [ "a-zA-Z0-9\\-/_=&?.:", [ 0, 500 ] ],

   //Location
   "country": [ "a-zA-Zá-úÁ-Ú\\-\\s", [ 2, 80 ] ],
   "state": [ "a-zA-Zá-úÁ-Ú\\-\\s", [ 2, 100 ] ],
   "city": [ "a-zA-Zá-úÁ-Ú\\-\\s", [ 2, 100 ] ],
   "address": [ "a-zA-Zá-úÁ-Ú0-9)(\\-\\s,;", [ 2, 255 ] ],
   "postal-code": [ "0-9\\-\\s", [ 3, 30 ] ],

   //texts
   "subject": [ "\\s\\S", [ 0, 300 ] ],
   "message": [ "\\s\\S", [ 0, 3000 ] ],
   "invalid": [ "\\s\\S", [ 0, 3000 ] ],

   //card
   "cc-number": [ "0-9\\s", [ 0, 25 ] ],
   "cc-expire-date": [ "0-9/", [ 5, 5 ] ],
   "cc-cvc": [ "0-9", [ 3, 3 ] ],
};

var regexValidation = {
   //data user
   "name": /^[a-zA-Zá-úÁ-Ú]{2,50}\s[a-zA-Zá-úÁ-Ú\s]{2,150}$/g,
   "full-name": /^[a-zA-Zá-úÁ-Ú]{2,50}\s[a-zA-Zá-úÁ-Ú]{2,150}$/g,
   "first-name": /^[a-zA-Zá-úÁ-Ú]{2,50}$/g,
   "last-name": /^[a-zA-Zá-úÁ-Ú]{2,50}$/g,
   "given-name": /^[a-zA-Zá-úÁ-Ú]{2,50}$/g,
   "family-name": /^[a-zA-Zá-úÁ-Ú]{2,50}$/g,

   //login/sign 
   "user-login": /^[a-zA-Z0-9!#@$%&*_=.+}/?'{~|~-]{3,200}$/g,
   "all-contacts": /^[a-zA-Z0-9!#@$%&*_=.+}/?'{~|~-]{3,200}$/g,
   "username": /^[a-zA-Z0-9._]{3,30}$/g,
   "email": /^[a-zA-Z0-9!#$%&*_=.+}/?'{~|~-]{2,}(?<!\.)@[a-z]{2,}\.[a-z]{2,}(\.[a-z]{2,})?$/g,
   "current-password": /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*\s{2})[a-zA-Z\d!@#¨$%^&*()_+\-=[\]{};':"|,.<>/?\s]{6,255}$/g,
   "access-password": /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*\s{2})[a-zA-Z\d!@#¨$%^&*()_+\-=[\]{};':"|,.<>/?\s]{4,20}$/g,
   "login-password": /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*\s{2})[a-zA-Z\d!@#¨$%^&*()_+\-=[\]{};':"|,.<>/?\s]{6,255}$/g,
   "password": /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*\s{2})[a-zA-Z\d!@#¨$%^&*()_+\-=[\]{};':"|,.<>/?\s]{6,255}$/g,
   "re-password": /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*\s{2})[a-zA-Z\d!@#¨$%^&*()_+\-=[\]{};':"|,.<>/?\s]{6,255}$/g,

   //texts
   "subject": /^[\s\S]{5,300}$/g,
   "message": /^[\s\S]{10,3000}$/g,
   "invalid": /^[\s\S]{1,3000}$/g,
   "birthdate": /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g,
   "url": /^https:\/\/([a-zA-z0-9\-_]{2,})\.([a-zA-Z0-9\-/_=&?.:]{2,500})$/g,

   //Location
   "country": /^[a-zA-Zá-úÁ-Ú\-\s]{2,80}$/g,
   "state": /^[a-zA-Zá-úÁ-Ú\-\s]{2,100}$/g,
   "city": /^[a-zA-Zá-úÁ-Ú\-\s]{2,100}$/g,
   "address": /^[a-zA-Zá-úÁ-Ú0-9)(\-\s,;]{2,255}$/g,
   "postal-code": /^[0-9\-\s]{3,30}$/g,

   //document data
   "cpf": /^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/g,
   "tel": /^[0-9()+\-\s]{8,30}$/g,
   "full-tel": /^[0-9()+\-\s]{8,30}$/g,

   //card
   "cc-number": /^[0-9\s]{10,24}$/g,
   "cc-expire-date": /^[0-9/]{5}$/g,
   "cc-cvc": /^[0-9]{3}$/g,
};

//FORMAT MASK
var toFormat = {
   "full-tel": DDIFormats,
   "cc-number": {}, //is object to set correct format
   "cc-expire-date": "00/00",
   "cc-cvc": "000",
   "birthdate": "00/00/0000",
   "cpf": "000.000.000-00",
   "rg": "00.000.000-0",
};
function multiFormats(v, e) {
   if (e.name === "cc-number") {
      return CCNumberElements(e);
   };

   if (e.value.length < 2) realFormat = null;

   if (typeof v === "object") {
      if (realFormat) {
         return realFormat
      };

      if (v[ e.value ]) {
         if (!realFormat) realFormat = v[ e.value ];
         return realFormat;
      }
      v = v[ "0" ];
   };

   return v;
}
function format(e, event) {
   if (!event.data) return;

   var format_ = toFormat[ e.name ];
   if (!format_) return;

   format_ = multiFormats(format_, e);

   if (format_.slice(e.value.length - 1, e.value.length) !== "0") {
      e.value = e.value + format_.slice(e.value.length - 1, e.value.length);
   }

   var newFormat = '';
   var index = 0;
   var replace0 = e.value.replace(/\D/g, '');

   for (var i = 0; i < format_.length; i++) {
      if (format_[ i ] === '0') {
         newFormat += replace0[ index ] || '';
         index++;
      } else {
         newFormat += format_[ i ];
      }
   }

   e.value = newFormat.slice(0, e.value.length);
};

//VALIDATE
function validate(e) {
   var format = regexValidation[ e.name ];
   format = new RegExp(format);
   if (!format.test(e.value)) {
      e.style.color = "#fffed2";
      return false;
   }
   e.style.color = "#bddaff";
   return true;
};
//PREVENT
function prevent(e) {
   var format = regexPrevent[ e.name ];
   if (!format) return;
   var test = new RegExp(`^[${format[ 0 ]}]$`, "g");
   var replace_ = new RegExp(`[^${format[ 0 ]}]`, "g");
   if (!test.test(e.value)) {
      e.value = e.value.replace(replace_, "");
      e.value = e.value.slice(0, format[ 1 ][ 1 ])
   }
};

//EVENT ACTION
function checkRePassword(e) {
   if (e.name === "re-password") {
      var repass = def.inputsElement[ "password" ];
      repass = e.value === repass.value;
      if (!repass) {
         delete dataForm[ e.name ];
      }
   };
}

function typing(e) {
   prevent(this, e);
   format(this, e);
   var getValidate = validate(this, e);
   this.classList.remove("error-message");

   if (getValidate) {
      dataForm[ this.name ] = this.value;
      checkRePassword(this);
      return dataForm;
   };

   if (def.required.indexOf(this.name) === -1) {
      def.required.push(this.name);
   }
   delete dataForm[ this.name ];
};

function keyPress(e) {
   var keyEnter = e.key === "Enter";
   keyEnter = keyEnter || e.code === "Enter";
   keyEnter = keyEnter && !e.shiftKey;

   if (keyEnter && !def.submiting) {
      PreSubmit();
   }
}

function keyDown() {
   if (this.value === "" && def.originalRequired.indexOf(this.name) === -1) {
      if (def.required.indexOf(this.name) !== -1) {
         def.required.splice(def.required.indexOf(this.name), 1);
      }
   }
}

function setScroll(elementTop) {
   w.scrollTo({
      top: elementTop - 100,
      behavior: "smooth"
   });
}

function success() {
   def.submiting = false;
   def.button.classList.remove("spinner");
}

function errorAfter(message) {
   if (!def.elementError) return;

   def.elementError.classList.add("show");
   def.elementError.textContent = message || "";
   def.button.classList.remove("spinner");
   def.submiting = false;
}

function PreSubmit() {
   var top = d.documentElement.scrollTop;

   if (def.elementError) {
      def.elementError.classList.remove("show");
      def.elementError.textContent = "";
   }

   def.inputs.forEach((e) => {
      e.classList.remove("error-message");
   });

   def.required.forEach(e => {
      if (!dataForm[ e ]) {
         setScroll(def.inputsElement[ e ][ gb ]().top + top);
         var setError = def.erros[ e ] || def.erros.invalid;
         var error = def.inputsElement[ e ].nextElementSibling;
         if (!error) throw new Error("Please define an span after input/textarea to set error message validation.")
         error.innerHTML = setError;
         def.inputsElement[ e ].classList.add("error-message");
         delete def.finalData[ e ];
         return;
      }

      def.finalData[ e ] = dataForm[ e ];

      var compare = Object.keys(def.finalData).length;
      compare = compare === def.required.length;

      if (compare && !def.submiting) {
         def.submiting = dataForm;
         if (def.callbackSubmit) {
            def.callbackSubmit(def.submiting, success, errorAfter);
         };

         setScroll(def.button[ gb ]().top + top - w.innerHeight / 2);
         def.button.classList.add("spinner");
      }
   });
}

/** 
 * @param {Object} config - Required* Container that contains the inputs
 * @param {String} config.container - Required* Container that contains the inputs
 * @param {String} config.buttonSubmit - Required* Button to submit form
 * @param {String} config.elementError - An element to show error message after backed if exist;
 * @param {Object} config.errorMessages - Se defina mensagens personalizadas algo como {name: "Invalid name", email: "..."} use o atributo name do input nas chaves
 * @param {Function} callbackSubmit - Required* callback to get data and send to backend, uma funcao para ser chamada caso sucesso, e uma funcao para ser chamada caso erro e deve ser passado como atributo a mensagem de erro
 * @returns 
 */
function InputFormatalidate(config, submitCallback) {
   def = {};
   dataForm = {};
   realFormat = null;

   erros(config.container, "container");
   erros(config.buttonSubmit, "button");
   def.container = d.querySelector(config.container);
   def.elementError = config.elementError ? d.querySelector(config.elementError) : null;
   def.inputs = def.container.querySelectorAll("input, textarea");
   def.inputsElement = {};
   def.callbackSubmit = submitCallback;
   def.button = d.querySelector(config.buttonSubmit);
   def.costomErrors = config.errorMessages;
   def.erros = { ...errorMessage, ...def.costomErrors };
   def.required = [];
   def.originalRequired = [];
   def.finalData = {};

   def.inputs.forEach((e) => {
      def.inputsElement[ e.name ] = e;

      if (e.getAttribute("data-required")) {
         def.required.push(e.name);
         def.originalRequired.push(e.name);
      }
      e.oninput = typing;
      e.onkeypress = keyPress;
      e.onkeydown = keyDown;
   });

   def.button.onclick = null;
   def.button.onclick = PreSubmit;

   return dataForm;
};

function erros(e, f) {
   if (e === "" || !e) {
      console.error({
         container: "Please define an valid selector, in config.container",
         button: "Please define an valid button selector, in config.buttonSubmit",
      }[ f ]);

      debugger;
   }
}

module.exports = { InputFormatalidate }
