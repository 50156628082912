import { useEffect } from "react";
import { setProgressBar } from "../login";
import { HistoryButtons, containersProfileChange } from "../profile";
import { Link } from "react-router-dom";

function activeSettingProp(e) {
    e = e.target.parentElement;
    e = e.parentElement;
    console.log(e);
    if (e.className !== "actived") {
        return e.className = "actived";
    }
    e.className = "";
}

export default function Settings() {
    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
        containersProfileChange()
    }, []);
    return (
        <div className="opacity-animation settings_">
            <HistoryButtons />
            <h3>Configurações</h3>
            <div>
                <h3>Inteligencia Artificial</h3>
                <div>
                    <span><span></span></span>
                    <p>Em breve</p>
                </div>
            </div>
            <div className="actived">
                <h3>Cookies</h3>
                <div>
                    <span onMouseUp={activeSettingProp}><span></span></span>
                    <p >Ativado</p>
                </div>
            </div>
            <div>
                <h3>Analitycs</h3>
                <div>
                    <span><span></span></span>
                    <p>Desativado</p>
                </div>
            </div>
            <div>
                <h3>Desativar conta</h3>
                <p>Você tem o periodo de 30 dias para reativação da conta e nao perder todos os dados. Está ação pode ser desfeita a qualquer momento.</p>
                <Link to="./deactivate-account">Desativar agora</Link>
            </div>
        </div>
    )
}