import { Helmet } from "react-helmet";

/* 
////////////////////// HOW TO USE 


website: Página genérica de um site.
article: Uma página que representa um artigo.
book: Um livro.
profile: Perfil de uma pessoa.
video.movie: Um filme.
video.episode: Um episódio de uma série ou programa.
video.tv_show: Uma série de TV.
video.other: Outro tipo de vídeo.
music.album: Um álbum de música.
music.song: Uma música.
music.playlist: Uma playlist de música.
place: Um lugar físico, como um local.
product: Um produto específico.
website.blog: Um blog.
article.news: Uma notícia.
article.blog: Um post de blog.
article.opinion: Uma opinião ou editorial.
article.report: Um relatório ou documento.
article.recipe: Uma receita culinária.
article.review: Uma crítica ou revisão.
article.satire: Um conteúdo satírico.
article.scholarly: Um artigo acadêmico ou científico.
website: Para páginas web em geral.
article: Para artigos.
video: Para conteúdo de vídeo.
music.song: Para músicas individuais.
music.album: Para álbuns musicais.
music.playlist: Para playlists de música.
music.radio_station: Para estações de rádio.
book: Para livros.
profile: Para perfis pessoais ou de negócios.
place: Para lugares físicos.
product: Para produtos específicos.
restaurant.menu: Para menus de restaurantes.
fitness.course: Para cursos de fitness.
game: Para jogos.
movie: Para filmes.
tv_show: Para programas de TV.
article.book: Para livros como artigos.
article.movie: Para filmes como artigos.
article.music: Para músicas como artigos.
article.place: Para lugares como artigos.
article.restaurant: Para restaurantes como artigos.
article.video: Para vídeos como artigos.
business.business: Para negócios.
business.company: Para empresas.
business.entertainment_business: Para negócios de entretenimento.
business.place: Para lugares comerciais.
business.restaurant: Para restaurantes.
cause: Para causas e movimentos sociais.
education.course: Para cursos educacionais.
education.school: Para instituições educacionais.
game.achievement: Para conquistas de jogos.
game.level: Para níveis de jogos.
music.album: Para álbuns musicais.
music.playlist: Para playlists de música.
music.radio_station: Para estações de rádio de música.
place.local_business: Para negócios locais.
place.restaurant: Para restaurantes.
product: Para produtos específicos.
product.book: Para livros como produtos.
product.clothing: Para roupas como produtos.

var headerAtributes = {
  site_name: "Chris Axxwell",
  page_type: "website", //tipo da pagina pode ser: website, video, audio, book, product, profile, place
  title: "Chris Axxwell | Engenheiro de Software",
  description: "s",
  page_author: "Chris Axxwell",
  index: "index, follow", //index, noindex (nao indexar tipo pagina de profile), follow, nofollow (nao siga os links da pagina), noarchive (para paginas temporarias, tipo promocoes, usuarios), none (noindex, nofollow, noarchive)
  keywords: "", //receita de batata, tenis, suplemento creatina, whey max sabor morango e tals
  canonical: "contact or any, add '' to home", 
  lang: "pt_BR",
  langs: ["en_US", "ru"],
  alternate: [
    { lang: "en-US", url: "us" },
    { lang: "ru", url: "ru" },
  ],
  theme_color: "#08080a",
  img_page: "page_image.jpg", //imagem que ta dentro de src/files/images/head/
  img_alt: "",
  twitter_username_creator: "chrisaxxwell",
  twitter_username: "chrisaxxwell.dev",
  icon: "icon.svg", //o icone da pagina em /src/files/
  server_url: "url do servidor ou undefined", //permite conectar com o servidor antes de ser referenciado, de haver uma solicitacao
  noCache: false, //adicone true, em paginas de usuario e paginas que nao quer adiionar no cache, se for true entao noa adicionapaginas no cache tipo para paginas de usuario, paginas de blogs atualizados e tals
  //redirect: ["https://www.chrisaxxwell.com", 3], //redireciona a pagina para outra pagina ou outro dominio depois de 3 segundos ou nao defina para 0
};

*/






/////////
//////////// ABILITAR O PRE-RENDER NA NETLIFY OBRIGATORIO PARA RENDERZIRAE NA GOOGLE CORRETAMENTE 
/////////
/////////
/////////


var Head = function ({ props }) {

   var origin = window.location.origin;
   var img = `${origin}`;

   return (
      <Helmet>
         <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"
         />
         <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
         {/* Use com moderacao o X-DNS-Prefetch-Control  */}
         <meta httpEquiv="X-DNS-Prefetch-Control" content="on" />
         <title>{props.title}</title>
         <meta name="description" content={props.description} />
         <link rel="canonical" href={`${origin}${props.canonical || ""}`} />
         <meta name="author" content={props.page_author} />
         <meta name="apple-mobile-web-app-capable" content="yes" />
         <meta name="apple-mobile-web-app-title" content={props.title} />
         <link
            rel="apple-touch-icon"
            href={`${props.icon || "icon.svg"}`}
         />
         {props.alternate
            ? props.alternate.map((e, i) => (
               <link
                  key={i}
                  rel="alternate"
                  hrefLang={e.lang}
                  href={`${origin}${e.url || ""}${props.canonical || ""}`}
               />
            ))
            : undefined}

         {/* Meta tags para redes sociais (Open Graph) */}
         <meta property="og:site_name" content={props.site_name} />
         <meta property="og:title" content={props.title} />
         <meta property="og:description" content={props.description} />
         <meta property="og:image" content={img + props.img_page} />
         <meta property="og:url" content={`${origin}${props.canonical || ""}`} />
         <meta property="og:type" content={props.page_type} />
         <meta property="og:locale" content={props.lang} />
         {props.langs
            ? props.langs.map((e, i) => (
               <meta key={i} property="og:locale:alternate" content={e} />
            ))
            : undefined}

         <meta name="lang" content={props.lang.replace("_", "-")} />
         <meta
            httpEquiv="content-language"
            content={props.lang.replace("_", "-")}
         />
         {/* <link
        rel="preload"
        href="/src/fonts/AxxwellPro/AxxwellPro-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />  */}

         {/* Meta tags para Twitter */}
         <meta name="twitter:card" content="summary_large_image" />
         <meta name="twitter:title" content={props.title} />
         <meta name="twitter:description" content={props.description} />
         <meta name="twitter:image" content={img + props.img_page} />
         <meta name="twitter:image:alt" content={props.img_alt} />
         <meta name="twitter:site" content={`@${props.twitter_username}`} />
         <meta
            name="twitter:creator"
            content={`@${props.twitter_username_creator}`}
         />
         <meta name="theme-color" content={props.theme_color || "#08080a"} />
         <meta name="twitter:domain" content={origin} />

         {/* Meta tags para indexação no Google */}
         <meta name="keywords" content={props.keywords} />
         <meta name="description" content={props.description} />
         <meta name="robots" content={props.index} />

         {/* Meta tags para outros motores de busca */}
         <meta name="bingbot" content={props.index} />
         <meta name="duckduckgo" content={props.index} />
         <meta name="yahoo-slurp" content={props.index} />
         <link
            rel="icon"
            href={`${props.icon || "icon.svg"}`}
            type="image/x-icon"
         />
         <link rel="preconnect" href={props.server_url || undefined} />
         <link
            rel="search"
            type="application/opensearchdescription+xml"
            title="Pesquisar"
            href="/opensearch.xml" /* Para pesquisas */
         />
         <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
         {props.noCache ? (
            <meta
               httpEquiv="Cache-Control"
               content="no-cache, no-store, must-revalidate"
            />
         ) : undefined}

         {props.noCache ? (
            <meta httpEquiv="Pragma" content="no-cache" />
         ) : undefined}

         {props.redirect ? (
            <meta
               httpEquiv="refresh"
               content={`${props.redirect[ 1 ] || 0};${props.redirect[ 0 ]}`}
            />
         ) : undefined}
      </Helmet>
   );
};

export default Head;
