import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "../css/ideas.css";
import { useEffect, useState } from "react";
import { setProgressBar } from "./login";
import { InputFormatalidate } from "../modules/input-formatalidate/input-formatalidate";
import { axiosCreate } from "./restrict/restrict";
import Head from "./head";

var d = document;
var w = window;

function IdeasHead(e) {
   e = !!e.home;

   return (
      <div className={e ? "intro-animation ideasHead" : "ideasHead"}>
         <h1>World Ideas</h1>
         <h2>The place where your ideas are embraced to make the world a better place.</h2>
         <h3>The best creations in the world came through ideas. Ideas shape the world, and <span>the world is made of ideas</span>. We all have ideas, and you have the ability to make the world a better place. Your ideas are welcome here. If your idea is accepted, you will receive <span className="price">$ 200.00</span> and your name will be featured in the project.</h3>
         {e ? <Link to="./ideas">Access page</Link> : null}
         <figure></figure>
      </div>
   )
}

function Ideas() {
   var [ errorMessage, setErrorMessage ] = useState("");
   var navigate = useNavigate();

   useEffect(function () {
      setErrorMessage("");

      /*  InputFormatalidate({
          container: ".submitIdea",
          buttonSubmit: ".submit-button",
          elementError: ".error-message-after",
          errorMessages: {
             name: "Nome invalido!"
          }
       }, async function (e, success, error) {
          await axiosCreate.post("/ideas/send", e).then(e => {
             navigate("thankyou-page", { state: e.data });
             success();
          }).catch(e => {
             error(e.response.data.message);
          });
       }) */

      setProgressBar();
      window.scrollTo(0, 0);

   }, []);

   return (
      <div id="ideas">
         <Head props={{
            site_name: "Chris Axxwell",
            page_type: "website",
            title: "Chris Axxwell - Ideas",
            description: "Make the world a better place. Your ideas are welcome here. If your idea is accepted, you will receive $ 200.00 and your name will be featured in the project.",
            page_author: "Chris Axxwell",
            index: "index, follow",
            keywords: "ideas, world, mundo, ideias, better place, save world",
            canonical: "/",
            lang: "en_US",
            theme_color: "#040404",
            img_page: "/chrisaxxwell-poster.jpg",
            img_alt: "Chris Axxwell Poster",
            twitter_username_creator: "chrisaxxwell",
            twitter_username: "chrisaxxwell",
            icon: "/logo.svg",
            noCache: false, //adicone true, em paginas de usuario e paginas que nao quer adiionar no cache, se for true entao noa adicionapaginas no cache tipo para paginas de usuario, paginas de blogs atualizados e tals
            //redirect: ["https://www.chrisaxxwell.com", 3], //redireciona a pagina para outra pagina ou outro dominio depois de 3 segundos ou nao defina para 0
         }} />

         <div className="view-animation">
            <IdeasHead />

            <p>I, <strong className="main-theme">Chris Axxwell</strong>, dedicate my time, which is the most valuable thing I have, to read and implement your idea. Thank you for your kindness.</p>

            <div id="idea-sect" className="submitIdea">
               <div>
                  <p>Name (Optional)</p>
                  <input title="" autoCapitalize="true" autoComplete="name" placeholder="eg: John Smith" type="text" required name="name" />
                  <span></span>
               </div>
               <div>
                  <p>Phone number (Optional)</p>
                  <input title="" autoComplete="tel" placeholder="eg: +12894638765" type="text" required name="full-tel" />
                  <span></span>
               </div>
               <div>
                  <p>Email (Optional)</p>
                  <input title="" autoComplete="email" placeholder="chrisaxxwell@gmail.com" type="email" required name="email" />
                  <span></span>
               </div>
               <div>
                  <p>Subject</p>
                  <input title="" autoComplete="text" data-required="true" placeholder="eg: Idea to end hunger in..." type="text" required name="subject" />
                  <span></span>
               </div>
               <div>
                  <p>Message</p>
                  <textarea title="" autoComplete="text" data-required="true" placeholder="eg: Hi, I have an idea..." type="text" required name="message" />
                  <span></span>
               </div>
               <p className="error-message-after"></p>
               <button className="submit-button"><svg viewBox="0 0 97 108" xmlns="http://www.w3.org/2000/svg"><path d="M20 0H32V35.312L43 31.8137V0H55V27.9973L66 24.4988V0H78V20.6824L89.5789 17L93.2158 28.4356L78 33.2747V43.6365L92.5789 39L96.2158 50.4356L78 56.2288V94H66V60.0452L55 63.5436V108H43V67.36L32 70.8584V94H20V74.6748L6.63696 78.9247L3 67.4891L20 62.0825V51.7207L3.63696 56.9247L0 45.4891L20 39.1284V0ZM32 58.2661L43 54.7678V44.4059L32 47.9043V58.2661ZM55 50.9514L66 47.4529V37.0911L55 40.5895V50.9514Z"></path></svg>{/* Send idea */}Coming soon</button>
               <p>Ideas are sent directly to me. If you choose to leave your contact information and name, I will get in touch if your idea is accepted by me. <span className="main-theme">Thank you, and let's change the world.</span>.</p>
            </div>
         </div>
      </div>
   )
}

function ThankYouIdeas() {
   var location = useLocation();
   var { state } = location;

   useEffect(function () {

      setProgressBar();
      window.scrollTo(0, 0);
   }, []);

   return (
      <div id="thank-you-ideas">
         <div>
            <h4 className="main-theme">Thank you {state[ "family-name" ] || ""}</h4>
            <h5>Thank you for your collaboration, we will contact you shortly if your idea has been accepted. Currently the value is a symbolic gesture for your collaboration, but we will have new features in the future.</h5>
         </div>
      </div>
   )
}

export default function IdeasSession() {
   return (
      <Routes>
         <Route path="*" Component={Ideas} />
         <Route path="/thankyou-page" Component={ThankYouIdeas} />
      </Routes>
   )
}

export { IdeasHead };