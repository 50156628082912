import { Link } from "react-router-dom";
import "../css/under-development.css";
import { useEffect } from "react";
import { setProgressBar } from "./login";

export default function UnderDevelopment() {
    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id="under-development">
            <div className="view-animation">
                <figure></figure>
                <h1>Em desenvolvimento</h1>
                <p>Eu <strong className="main-theme">Chris Axxwell</strong> estou em meio ao desenvolvimento gradual desta página, dedicando tempo e atenção para construir cada parte com cuidado e precisão. A cada passo, estou adicionando novos elementos, aprimorando o design e a funcionalidade para proporcionar uma experiência completa e satisfatória aos visitantes, obrigado pela compreenção.</p>
                <Link to="../sitemap">Mapa do site</Link>
            </div>
        </div>
    )
}