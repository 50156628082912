export default function unEscape(string) {
    var rgx = /&amp;|&lt;|&gt;|&quot;|&#39;|&#x2F;|&#x60;|&#x3D;/g;
    return string.replace(rgx, function (e) {
        return ({
            "&amp;": "&",
            "&lt;": "<",
            "&gt;": ">",
            "&quot;": "\"",
            "&#39;": "'",
            "&#x2F;": "/",
            "&#x60;": "`",
            "&#x3D;": "="
        })[ e ];
    });
} 