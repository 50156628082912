import { Link } from "react-router-dom";
import "../css/footer.css";
import { useShareProjects } from "./home";

var WhatsApp = {
   tel: 5574999112214,
   message:
      "Hi Chris, I would like to...",
   url(message) {
      message = encodeURIComponent(message || this.message);
      return `https://wa.me/${this.tel}?text=${message}`;
   },
};

export default function Footer() {
   var { projects } = useShareProjects();

   return (
      <footer>
         <Link className="flex-center logo-axxwell" to="./">
            <svg viewBox="0 0 97 108" xmlns="http://www.w3.org/2000/svg">
               <path d="M20 0H32V35.312L43 31.8137V0H55V27.9973L66 24.4988V0H78V20.6824L89.5789 17L93.2158 28.4356L78 33.2747V43.6365L92.5789 39L96.2158 50.4356L78 56.2288V94H66V60.0452L55 63.5436V108H43V67.36L32 70.8584V94H20V74.6748L6.63696 78.9247L3 67.4891L20 62.0825V51.7207L3.63696 56.9247L0 45.4891L20 39.1284V0ZM32 58.2661L43 54.7678V44.4059L32 47.9043V58.2661ZM55 50.9514L66 47.4529V37.0911L55 40.5895V50.9514Z" />
            </svg>
         </Link>
         <p className="main-theme escrita notranslate" translate="no">chris axxwell.</p>
         <p>
            All services offered by us are registered in our <a
               href="https://en.wikipedia.org/wiki/Customer_relationship_management"
               target="_blank"
               rel="noopener noreferrer"
            > CRM </a> system
            , ensuring personalized monitoring of each project and maximum security of your information. In the digital age we live in, the security of your data is more than a concern - it is a priority. Here, we understand the importance of protecting what is most valuable to you: your information. With an unwavering commitment to <a
               href="https://en.wikipedia.org/wiki/Computer_security"
               target="_blank"
               rel="noopener noreferrer"
            >
               cybersecurity
            </a>
            , we continually invest in cutting-edge technologies to ensure that
            your data is always protected against {" "}
            <a
               href="https://en.wikipedia.org/wiki/Computer_security"
               target="_blank"
               rel="noopener noreferrer"
            >
               digital threats
            </a>
            . From initial collection to storage and processing, every step of our process is designed with the highest security standards in mind.
            <br />
            <br />
            Count on us to ensure complete peace of mind at every stage of your project. We are committed to providing effective and reliable solutions to meet your needs, with the assurance that your data is in good hands. You can request our services in several ways: through{" "}<a
               href={
                  "mailto:chrisaxxwell@gmail.com?subject=Contato pelo Site&body=" +
                  WhatsApp.message
               }
               target="_blank"
               rel="noreferrer"
            >
               Email
            </a>, <a href={`tel:+${WhatsApp.tel}`} target="_blank" rel="noreferrer">
               Call
            </a>, <a href={WhatsApp.url()} target="_blank" rel="noreferrer">
               WhatsApp
            </a>, ou <a href="httos://instagram.com/chrisaxxwell.dev" target="_blank" rel="noreferrer">
               Instagram
            </a>
            . We are always available to listen to your ideas and turn them into reality. Our expertise covers a wide range of website work.<a href="https://en.wikipedia.org/wiki/Website" target="_blank" rel="noreferrer">
               Website
            </a>, including <a href="https://pt.wikipedia.org/wiki/Landing_page" target="_blank" rel="noreferrer">
               Landing pages
            </a>, institutional websites, <a href="https://pt.wikipedia.org/wiki/Blog" target="_blank" rel="noreferrer">
               Blogs
            </a>, <a
               href="https://en.wikipedia.org/wiki/Online_shopping"
               target="_blank"
               rel="noopener noreferrer"
            > e-commerce
            </a>  and much more.
            <br />
            <br />
            In addition, we develop {" "}
            <a
               href="https://en.wikipedia.org/wiki/Mobile_app"
               target="_blank"
               rel="noopener noreferrer"
            >
               mobile apps
            </a> tailored to your needs, such as<a
               href="https://en.wikipedia.org/wiki/Online_shopping"
               target="_blank"
               rel="noopener noreferrer"
            >
               online shopping
            </a>, <a
               href="https://en.wikipedia.org/wiki/Social_media"
               target="_blank"
               rel="noopener noreferrer"
            >
               social media
            </a>
            , <a
               href="https://en.wikipedia.org/wiki/Online_chat"
               target="_blank"
               rel="noopener noreferrer"
            >
               online chats
            </a> and many others. Not limited to just mobile platforms, we also
            develop applications for Windows, Mac and Linux, as well as{" "}
            <a
               href="https://en.wikipedia.org/wiki/Browser_extension"
               target="_blank"
               rel="noopener noreferrer"
            >
               extensions for Google Chrome
            </a>. We create <a
               href="https://pt.wikipedia.org/wiki/Web_API"
               target="_blank"
               rel="noopener noreferrer"
            >APIs
            </a> customized for various purposes, offering advanced features
            such as integration of<a
               href="https://pt.wikipedia.org/wiki/CAPTCHA"
               target="_blank"
               rel="noopener noreferrer"
            >captchas
            </a> and other security mechanisms, as well as innovative implementations
            that meet your specific needs. Our team also excels
            in creating {" "}<a
               href="https://pt.wikipedia.org/wiki/Framework"
               target="_blank"
               rel="noopener noreferrer" > customized frameworks</a> to optimize the development of your projects,
            ensuring efficiency and quality in each delivery.
            <br />
            <br />
            Let us take care of the details while you focus on what
            really matters - making your business grow and prosper. Join us
            today and enjoy the peace of mind that comes with knowing
            your data is in good hands.
         </p>
         <div>
            <div>
               <p>Projects</p>
               {projects.slice(0, 16).map((e, i) => (
                  i === 15 ? (
                     <Link key={i} to="./credentials">See all</Link>
                  ) : (
                     <a title={e[ "project-name" ]} key={i} href={e.url} target="_blank" rel="noreferrer">{e[ "project-name" ].slice(0, 15) + (e[ "project-name" ].length > 15 ? "..." : "")}</a>
                  )
               ))}
            </div>
            <div>
               <p>Services</p>
               <Link to={WhatsApp.url()} target="_blank">Websites</Link>
               {/* https://wa.me/5511912345678?text=Olá%2C%20gostaria%20de%20mais%20informações%21 */}
               <Link to={WhatsApp.url()} target="_blank">Mobile apps</Link>
               <Link to={WhatsApp.url()} target="_blank">Design</Link>
               <Link to={WhatsApp.url()} target="_blank">Games</Link>
               <Link to={WhatsApp.url()} target="_blank">Extensions</Link>
               <Link to={WhatsApp.url()} target="_blank">Api</Link>

               <p>Internal</p>
               <Link to="./sitemap">Sitemap</Link>
               <Link to="./credentials">Credentials</Link>
               <Link to={WhatsApp.url()} target="_blank">Terms</Link>
               <Link to={WhatsApp.url()} target="_blank">Privacy</Link>
               <Link to="./credentials/login">Login</Link>
               <Link to="./credentials/login/recovery">Forgot password?</Link>
               <Link to="./credentials/signup">Sign Up</Link>
            </div>
            <div>
               <p>Social</p>
               <Link to="https://www.instagram.com/chrisaxxwell.dev/" target="_blank">Instagram</Link>
               <Link to="" target="_blank">Youtube</Link>
               <Link to="https://github.com/chrisaxxwell" target="_blank">GitHub</Link>
               <Link to="https://x.com/chrisaxxwell" target="_blank">X (Twitter)</Link>
               <Link to="https://dribbble.com/chrisaxxwell" target="_blank">Dribble</Link>

               <p>Contacts</p>
               <Link to={WhatsApp.url()} target="_blank">Support</Link>
               <Link to="">Blog</Link>
               <Link to={WhatsApp.url()} target="_blank">Contacts</Link>
               <Link to="mailto:chrisaxxwell@gmail.com" target="_blank">Email</Link>
               <Link to="">Newsletter</Link>
               <Link to="">Events</Link>
               <Link to="">Api</Link>
            </div>
         </div>
         <div>
            <p>
               Copyright © {new Date().getFullYear()} Chris Axxwell, Dev. All
               rights reserved.
            </p>
            <p>
               Developed by:{" "}
               <a className="main-theme" href="https://instagram.com/chrisaxxwell.dev" target="_blank" rel="noreferrer">
                  @chrisaxxwell.dev
               </a>
            </p>
         </div>
      </footer>
   )
}

export { WhatsApp }