import { useEffect } from "react";
import { setProgressBar } from "../login";
import { HistoryButtons, containersProfileChange } from "../profile";
import { InputFormatalidate } from "../../modules/input-formatalidate/input-formatalidate";
import { Link, Route, Routes } from "react-router-dom";
import { ShareConfirmPassword } from "../header";

function Security() {
    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
        containersProfileChange()
    }, []);
    return (
        <div className="opacity-animation security-sct">
            <HistoryButtons />
            <h3>Segurança e senhas</h3>
            <h4>Alterar senha da conta</h4>
            <h5>Altere a sua senha de login abaixo:</h5>
            <Link to="account-password" >Alterar senha</Link>
            <h4>Alterar senha de acesso</h4>
            <h5>Está senha garante o acesso em determinados locais reestritos do seu perfil, a senha atual é sua senha de login, acesse abaixo para alterar:</h5>
            <Link to="access-password" >Alterar senha de acesso</Link>
            <h4>Authenticação de dois fatores (F2A)</h4>
            <h5>A autenticação de 2 fatores e essencial para garantir uma etapa de segurança maior. aonde você recebe um codigo em seu celular para acessar sua conta:</h5>
            <Link to="F2A" >Sessao de autenticação</Link>
            <h4>Perguntas de authenticação</h4>
            <h5>Esta autenticação e uma forma de segurança aonde você responde algumas perguntas simples:</h5>
            <Link to="ask-authenticator" >Sessao de autenticação</Link>
            <h4>Where you're logged in</h4>
            <h5>Todos os dispositivos em que você esta conectado:</h5>
            <Link to="logged-in" >Todos os logins</Link>
        </div>
    )
}

function F2A() {

    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
        containersProfileChange()
    }, []);
    return (
        <div className="opacity-animation security-sct">
            <HistoryButtons />
            <h3>Autenticacao de 2 fatores</h3>
        </div>
    )
}

function AskAuthentication() {

    useEffect(function () {
        setProgressBar();
        window.scrollTo(0, 0);
        containersProfileChange()
    }, []);
    return (
        <div className="opacity-animation security-sct">
            <HistoryButtons />
            <h3>Ask autenticator</h3>
        </div>
    )
}

function AccountPassword() {

    useEffect(function () {

        InputFormatalidate({
            buttonSubmit: ".change-pass-btn",
            container: ".change-pass",
            errorMessages: {}
        }, function (e) {
            console.log("change Password");
            console.table(e);
        });

        setProgressBar();
        window.scrollTo(0, 0);
        containersProfileChange()
    }, []);
    return (
        <div className="opacity-animation security-sct">
            <HistoryButtons />
            <h3>Alterar senha</h3>
            <div className="change-pass">
                <div>
                    <h4>Senha atual</h4>
                    <figure onClick={(e) => {
                        if (!window.actualPasssecShow) {
                            window.actualPasssecShow = true;
                            e.target.className = "hide";
                            e.target.nextElementSibling.type = "text";
                            return;
                        }
                        window.actualPasssecShow = false;
                        e.target.nextElementSibling.type = "password";
                        e.target.className = "";
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 35"><path fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 1.1 1.1 0 2.5 0h42a2.5 2.5 0 0 1 0 5h-42A2.5 2.5 0 0 1 0 2.5m2.2 4.4C3.6 22.8 12.4 35 23 35S42.4 22.8 43.8 7h-6c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q13.7 20 17 20c6.5 0 12-5.5 13.5-13H3zm59 .1C62.6 22.8 71.4 35 82 35c10.8 0 19.6-12.5 20.9-28.6a3 3 0 0 1-1.9.6h-4.2c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q72.7 20 76 20c6.5 0 12-5.5 13.5-13zm-.7-7a2.5 2.5 0 0 0 0 5h42a2.5 2.5 0 1 0 0-5z" /></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 65">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36 32.5c0 8-2.1 15-5.2 19.7-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8 2.6 1.5 5.8 1.6c7.7 0 14-7.8 14-17.5S22.7 15 15 15q-3 0-5.9 1.6l2-3.8C14.4 7.9 18 6 21 6s6.7 2 9.8 6.8A37 37 0 0 1 36 32.5m6 0c0 18-9.4 32.5-21 32.5S0 50.4 0 32.5C0 14.5 9.4 0 21 0s21 14.6 21 32.5m46 0C88 42.2 81.7 50 74 50q-3 0-5.9-1.6l2 3.8C73.4 57.1 77 59 80 59s6.7-2 9.8-6.8A37 37 0 0 0 95 32.5c0-8-2.1-15-5.2-19.7C86.7 7.9 83.1 6 80 6c-3 0-6.7 2-9.8 6.8q-1.2 1.7-2 3.8Q70.7 15 74 15c7.7 0 14 7.8 14 17.5m13 0c0 18-9.4 32.5-21 32.5S59 50.4 59 32.5C59 14.5 68.4 0 80 0s21 14.6 21 32.5" /></svg>
                    </figure>
                    <input title="" type="password" name="current-password" required data-required placeholder="⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆" />
                    <span></span>
                </div>
                <div>
                    <h4>Nova senha</h4>
                    <figure onClick={(e) => {
                        if (!window.securityShowPassNew) {
                            window.securityShowPassNew = true;
                            e.target.className = "hide";
                            e.target.nextElementSibling.type = "text";
                            return;
                        }
                        window.securityShowPassNew = false;
                        e.target.nextElementSibling.type = "password";
                        e.target.className = "";
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 35"><path fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 1.1 1.1 0 2.5 0h42a2.5 2.5 0 0 1 0 5h-42A2.5 2.5 0 0 1 0 2.5m2.2 4.4C3.6 22.8 12.4 35 23 35S42.4 22.8 43.8 7h-6c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q13.7 20 17 20c6.5 0 12-5.5 13.5-13H3zm59 .1C62.6 22.8 71.4 35 82 35c10.8 0 19.6-12.5 20.9-28.6a3 3 0 0 1-1.9.6h-4.2c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q72.7 20 76 20c6.5 0 12-5.5 13.5-13zm-.7-7a2.5 2.5 0 0 0 0 5h42a2.5 2.5 0 1 0 0-5z" /></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 65">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36 32.5c0 8-2.1 15-5.2 19.7-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8 2.6 1.5 5.8 1.6c7.7 0 14-7.8 14-17.5S22.7 15 15 15q-3 0-5.9 1.6l2-3.8C14.4 7.9 18 6 21 6s6.7 2 9.8 6.8A37 37 0 0 1 36 32.5m6 0c0 18-9.4 32.5-21 32.5S0 50.4 0 32.5C0 14.5 9.4 0 21 0s21 14.6 21 32.5m46 0C88 42.2 81.7 50 74 50q-3 0-5.9-1.6l2 3.8C73.4 57.1 77 59 80 59s6.7-2 9.8-6.8A37 37 0 0 0 95 32.5c0-8-2.1-15-5.2-19.7C86.7 7.9 83.1 6 80 6c-3 0-6.7 2-9.8 6.8q-1.2 1.7-2 3.8Q70.7 15 74 15c7.7 0 14 7.8 14 17.5m13 0c0 18-9.4 32.5-21 32.5S59 50.4 59 32.5C59 14.5 68.4 0 80 0s21 14.6 21 32.5" /></svg>
                    </figure>
                    <input title="" type="password" name="password" required data-required placeholder="⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆" />
                    <span></span>
                </div>
                <div>
                    <h4>Nova senha</h4>
                    <figure onClick={(e) => {
                        if (!window.securityShowPassNew) {
                            window.securityShowPassNew = true;
                            e.target.className = "hide";
                            e.target.nextElementSibling.type = "text";
                            return;
                        }
                        window.securityShowPassNew = false;
                        e.target.nextElementSibling.type = "password";
                        e.target.className = "";
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 35"><path fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 1.1 1.1 0 2.5 0h42a2.5 2.5 0 0 1 0 5h-42A2.5 2.5 0 0 1 0 2.5m2.2 4.4C3.6 22.8 12.4 35 23 35S42.4 22.8 43.8 7h-6c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q13.7 20 17 20c6.5 0 12-5.5 13.5-13H3zm59 .1C62.6 22.8 71.4 35 82 35c10.8 0 19.6-12.5 20.9-28.6a3 3 0 0 1-1.9.6h-4.2c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q72.7 20 76 20c6.5 0 12-5.5 13.5-13zm-.7-7a2.5 2.5 0 0 0 0 5h42a2.5 2.5 0 1 0 0-5z" /></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 65">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36 32.5c0 8-2.1 15-5.2 19.7-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8 2.6 1.5 5.8 1.6c7.7 0 14-7.8 14-17.5S22.7 15 15 15q-3 0-5.9 1.6l2-3.8C14.4 7.9 18 6 21 6s6.7 2 9.8 6.8A37 37 0 0 1 36 32.5m6 0c0 18-9.4 32.5-21 32.5S0 50.4 0 32.5C0 14.5 9.4 0 21 0s21 14.6 21 32.5m46 0C88 42.2 81.7 50 74 50q-3 0-5.9-1.6l2 3.8C73.4 57.1 77 59 80 59s6.7-2 9.8-6.8A37 37 0 0 0 95 32.5c0-8-2.1-15-5.2-19.7C86.7 7.9 83.1 6 80 6c-3 0-6.7 2-9.8 6.8q-1.2 1.7-2 3.8Q70.7 15 74 15c7.7 0 14 7.8 14 17.5m13 0c0 18-9.4 32.5-21 32.5S59 50.4 59 32.5C59 14.5 68.4 0 80 0s21 14.6 21 32.5" /></svg>
                    </figure>
                    <input title="" type="password" name="re-password" required data-required placeholder="⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆⋆" />
                    <span></span>
                </div>
                <button className="profile-button change-pass-btn"><span>Alterar senha</span></button>
            </div>
        </div>
    )
}

function AccessPassword() {
    useEffect(function () {

        InputFormatalidate({
            buttonSubmit: ".change-access-pass-btn",
            container: ".access-pass",
            errorMessages: {}
        }, function (e) {
            console.log("change Acccess Password");
            console.table(e);
        });

        setProgressBar();
        window.scrollTo(0, 0);
        containersProfileChange()
    }, []);

    return (
        <div className="opacity-animation security-sct">
            <HistoryButtons />
            <h3>Alterar senha de acesso</h3>
            <div className="access-pass">
                <div>
                    <h4>Senha de login</h4>
                    <figure onClick={(e) => {
                        if (!window.actualPassLta) {
                            window.actualPassLta = true;
                            e.target.className = "hide";
                            e.target.nextElementSibling.type = "text";
                            return;
                        }
                        window.actualPassLta = false;
                        e.target.nextElementSibling.type = "password";
                        e.target.className = "";
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 35"><path fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 1.1 1.1 0 2.5 0h42a2.5 2.5 0 0 1 0 5h-42A2.5 2.5 0 0 1 0 2.5m2.2 4.4C3.6 22.8 12.4 35 23 35S42.4 22.8 43.8 7h-6c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q13.7 20 17 20c6.5 0 12-5.5 13.5-13H3zm59 .1C62.6 22.8 71.4 35 82 35c10.8 0 19.6-12.5 20.9-28.6a3 3 0 0 1-1.9.6h-4.2c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q72.7 20 76 20c6.5 0 12-5.5 13.5-13zm-.7-7a2.5 2.5 0 0 0 0 5h42a2.5 2.5 0 1 0 0-5z" /></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 65">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36 32.5c0 8-2.1 15-5.2 19.7-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8 2.6 1.5 5.8 1.6c7.7 0 14-7.8 14-17.5S22.7 15 15 15q-3 0-5.9 1.6l2-3.8C14.4 7.9 18 6 21 6s6.7 2 9.8 6.8A37 37 0 0 1 36 32.5m6 0c0 18-9.4 32.5-21 32.5S0 50.4 0 32.5C0 14.5 9.4 0 21 0s21 14.6 21 32.5m46 0C88 42.2 81.7 50 74 50q-3 0-5.9-1.6l2 3.8C73.4 57.1 77 59 80 59s6.7-2 9.8-6.8A37 37 0 0 0 95 32.5c0-8-2.1-15-5.2-19.7C86.7 7.9 83.1 6 80 6c-3 0-6.7 2-9.8 6.8q-1.2 1.7-2 3.8Q70.7 15 74 15c7.7 0 14 7.8 14 17.5m13 0c0 18-9.4 32.5-21 32.5S59 50.4 59 32.5C59 14.5 68.4 0 80 0s21 14.6 21 32.5" /></svg>
                    </figure>
                    <input title="" type="password" name="current-password" placeholder="⋆⋆⋆⋆" required data-required />
                    <span></span>
                </div>
                <div>
                    <h4>Nova senha de acesso</h4>
                    <figure onClick={(e) => {
                        if (!window.securityShowPassNewReAccess) {
                            window.securityShowPassNewReAccess = true;
                            e.target.className = "hide";
                            e.target.nextElementSibling.type = "text";
                            return;
                        }
                        window.securityShowPassNewReAccess = false;
                        e.target.nextElementSibling.type = "password";
                        e.target.className = "";
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 35"><path fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 1.1 1.1 0 2.5 0h42a2.5 2.5 0 0 1 0 5h-42A2.5 2.5 0 0 1 0 2.5m2.2 4.4C3.6 22.8 12.4 35 23 35S42.4 22.8 43.8 7h-6c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q13.7 20 17 20c6.5 0 12-5.5 13.5-13H3zm59 .1C62.6 22.8 71.4 35 82 35c10.8 0 19.6-12.5 20.9-28.6a3 3 0 0 1-1.9.6h-4.2c-.6 6-2.5 11.4-5 15.2-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8Q72.7 20 76 20c6.5 0 12-5.5 13.5-13zm-.7-7a2.5 2.5 0 0 0 0 5h42a2.5 2.5 0 1 0 0-5z" /></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 65">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36 32.5c0 8-2.1 15-5.2 19.7-3.1 4.9-6.7 6.8-9.8 6.8-3 0-6.7-2-9.8-6.8q-1.2-1.7-2-3.8 2.6 1.5 5.8 1.6c7.7 0 14-7.8 14-17.5S22.7 15 15 15q-3 0-5.9 1.6l2-3.8C14.4 7.9 18 6 21 6s6.7 2 9.8 6.8A37 37 0 0 1 36 32.5m6 0c0 18-9.4 32.5-21 32.5S0 50.4 0 32.5C0 14.5 9.4 0 21 0s21 14.6 21 32.5m46 0C88 42.2 81.7 50 74 50q-3 0-5.9-1.6l2 3.8C73.4 57.1 77 59 80 59s6.7-2 9.8-6.8A37 37 0 0 0 95 32.5c0-8-2.1-15-5.2-19.7C86.7 7.9 83.1 6 80 6c-3 0-6.7 2-9.8 6.8q-1.2 1.7-2 3.8Q70.7 15 74 15c7.7 0 14 7.8 14 17.5m13 0c0 18-9.4 32.5-21 32.5S59 50.4 59 32.5C59 14.5 68.4 0 80 0s21 14.6 21 32.5" /></svg>
                    </figure>
                    <input title="" type="password" name="access-password" placeholder="⋆⋆⋆⋆" required data-required />
                    <span></span>
                </div>
                <button className="profile-button change-access-pass-btn"><span>Alterar senha</span></button>
            </div>
        </div>
    )
}

function LoggedIn() {
    useEffect(function () {

        setProgressBar();
        window.scrollTo(0, 0);
        containersProfileChange()
    }, []);

    return (
        <div className="opacity-animation security-sct">
            <HistoryButtons />
            <h3>Where you're logged in</h3>
            <div>
                <div>
                    <p>iPhone 8
                        <br></br> Juàzeiro, BA, Brazil
                        <br></br>  May 23 at 6:45 PM</p>
                    <button>Sair</button>
                </div>
                <div>
                    <p>iPhone 8
                        <br></br> Juàzeiro, BA, Brazil
                        <br></br>  May 23 at 6:45 PM</p>
                    <button>Sair</button>
                </div>
                <div>
                    <p>iPhone 8
                        <br></br> Juàzeiro, BA, Brazil
                        <br></br>  May 23 at 6:45 PM</p>
                    <button>Sair</button>
                </div>
                <button>Sair de todos</button>
            </div>
        </div>
    )
}

export default function SessionSecurity() {
    var { allowed, confirmed, notAllowed } = ShareConfirmPassword();

    useEffect(() => {
        notAllowed();
        confirmed();
    }, [])

    return (
        <Routes>
            <Route path="*" Component={allowed ? Security : null} />
            <Route path="account-password" Component={allowed ? AccountPassword : null} />
            <Route path="access-password" Component={allowed ? AccessPassword : null} />
            <Route path="ask-authenticator" Component={allowed ? AskAuthentication : null} />
            <Route path="F2A" Component={allowed ? F2A : null} />
            <Route path="logged-in" Component={allowed ? LoggedIn : null} />
        </Routes>
    )
} 