import { useEffect } from "react";
import { setProgressBar } from "../login";
import { Link } from "react-router-dom";
import { HistoryButtons, containersProfileChange } from "../profile";
import { InputFormatalidate } from "../../modules/input-formatalidate/input-formatalidate";

export default function Ticket() {
   useEffect(function () {
      InputFormatalidate({
         container: "#ticket-request",
         buttonSubmit: ".ticket-request-btn",
         errorMessages: {}
      }, function (e) {
         console.log("Send ticket");
         console.table(e)
      })
      setProgressBar();
      window.scrollTo(0, 0);
      containersProfileChange()
   }, []);
   return (
      <div className="opacity-animation ticket">
         <HistoryButtons />
         <h3>Chamados</h3>
         <div>
            <h4>Lista de todos os chamados</h4>
            <div>
               <p className="main-theme">Atendimento</p>
               <p>Preciso de ajuda aqui no projeto tal.</p>
               <p>Gostaria de solicitar um projeto tal para disponibilidade em tal lugar, e estou com uma ideia revolucionaia sobre como estamso bem revestidos aqui.</p>
               <p>ID:  <code>2342452411234123</code></p>
               <p>Requerente: Samantha de moraes silva</p>
               <p>Solicitado em: 14/12/2024</p>
               <p>Status: <strong className="status answered">Respondido</strong></p>
               <Link to="../ticket-request/2342452411234123">Ver resposta</Link>
            </div>
            <div>
               <p className="main-theme">Cancelamento</p>
               <p>Preciso de ajuda aqui no projeto tal.</p>
               <p>Gostaria de solicitar um projeto tal para disponibilidade em tal lugar, e estou com uma ideia revolucionaia sobre como estamso bem revestidos aqui.</p>
               <p>ID:  <code>2342452411234123</code></p>
               <p>Requerente: Samantha de moraes silva</p>
               <p>Solicitado em: 14/12/2024</p>
               <p>Status: <strong className="status processing">Aguardando resposta</strong></p>
               <Link to="../ticket-request/2342452411234123">Ver resposta</Link>
            </div>
            <div>
               <p className="main-theme">Elogios</p>
               <p>Preciso de ajuda aqui no projeto tal.</p>
               <p>Gostaria de solicitar um projeto tal para disponibilidade em tal lugar, e estou com uma ideia revolucionaia sobre como estamso bem revestidos aqui.</p>
               <p>ID:  <code>2342452411234123</code></p>
               <p>Requerente: Samantha de moraes silva</p>
               <p>Solicitado em: 14/12/2024</p>
               <p>Status: <strong className="status not-solved">Não solucionado</strong></p>
               <Link to="../ticket-request/2342452411234123">Ver resposta</Link>
            </div>
         </div>
         <h3>Abrir Chamado</h3>
         <div id="ticket-request">
            <h4>Selecione o tipo de chamado</h4>
            <select>
               <option value="service" defaultValue>Atendimento</option>
               <option value="financial">Financeiro</option>
               <option value="support">Suporte</option>
               <option value="claims">Reclamação</option>
               <option value="compliments">Elogios</option>
               <option value="contribution">Contribuição</option>
               <option value="cancel">Cancelamento</option>
               <option value="ideas">Ideias</option>
               <option value="other">Outro</option>
            </select>
            <figure><svg viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M10,-2.27373675e-13 C15.51,-2.27373675e-13 20,4.49 20,10 C20,15.52 15.51,20 10,20 L10,20 L9.72023508,19.9961558 C4.32942258,19.8478368 -2.26761352e-13,15.4264407 -2.26761352e-13,10 C-2.26761352e-13,4.49 4.48,-2.27373675e-13 10,-2.27373675e-13 Z M8.56,5.78 C8.36,5.78 8.17,5.85 8.02,6 C7.73,6.3 7.73,6.77 8.03,7.06 L8.03,7.06 L10.98,10 L8.03,12.94 C7.73,13.23 7.73,13.71 8.02,14 C8.32,14.3 8.79,14.3 9.08,14 L9.08,14 L12.57,10.53 C12.71,10.39 12.79,10.2 12.79,10 C12.79,9.8 12.71,9.61 12.57,9.47 L12.57,9.47 L9.08,6 C8.94,5.85 8.75,5.78 8.56,5.78 Z"></path></svg></figure>
            <div>
               <h4>Nome do solicitante</h4>
               <input title="" autoComplete="name" type="text" placeholder="Samantha de Moraes Silva" name="full-name" required />
               <span></span>
            </div>
            <div>
               <h4>Assunto</h4>
               <input title="" autoComplete="text" type="text" placeholder="Informação sobre..." name="subject" required data-required />
               <span></span>
            </div>
            <div>
               <h4>Mensagem</h4>
               <textarea title="" autoComplete="text" type="text" placeholder="Gostaria de saber / Estou com um problema em..." name="message" required data-required />
               <span></span>
            </div>
            <h5>Por favor aguarde que retornaremos o mais breve possivel. Os chamados são respondidos em ordem de solicitação.</h5>
            <button className="profile-button ticket-request-btn"><span>Enviar chamado / outro</span></button>
         </div>
      </div>
   )
}

function TicketRequest() {
   useEffect(function () {
      InputFormatalidate({
         container: "#reply-request",
         buttonSubmit: ".reply-request-btn",
         errorMessages: {}
      }, function (e) {
         console.log("send Reply");
         console.table(e);
      })
      setProgressBar();
      window.scrollTo(0, 0);
   }, []);
   return (
      <div className="opacity-animation ticket-request">
         <HistoryButtons />
         <h3>Chamados</h3>
         <div className="">
            <p className="main-theme">Chris Axxwell</p>
            <h6>Olá boa tarde, este problema e seguido por alguma solicitacao de suprimento de estamos em alguns lugares e esse governo e muito doido, mermao ta louco.</h6>
         </div>
         <div className="client">
            <p>Samantha Moraes</p>
            <h6>Sim o porem e que esse povo e tudo doido mano, eu nao donsigo entender isso, o cara falando de cavalo</h6>
         </div>
         <div>
            <p className="main-theme">Chris Axxwell</p>
            <h6>Esse lula e muito doido, parece que ta falando asneira, falando bosta no microfone, pelamor de deus mano, ta doido, povo maluco do caralho pelamor, Esse lula e muito doido, parece que ta falando asneira, falando bosta no microfone, pelamor de deus mano, ta doido, povo maluco do caralho pelamor, Esse lula e muito doido, parece que ta falando asneira, falando bosta no microfone, pelamor de deus mano, ta doido, povo maluco do caralho pelamor, </h6>
         </div>
         <div className="client">
            <p>Samantha Moraes</p>
            <h6>Sim o porem e que esse povo e tudo doido mano, eu nao donsigo entender isso, o cara falando de cavalo</h6>
         </div>
         <div id="reply-request">
            <h4>Responder</h4>
            <textarea title="" autoComplete="text" placeholder="Digite sua reposta..." type="text" name="message" required data-required />
            <span></span>
         </div>
         <button className="profile-button reply-request-btn"><span>Responder</span></button>
      </div>
   )
}

export { TicketRequest }